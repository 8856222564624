jQuery(document).ready(function () {
    if ($("#totuus_version").length) {
        $("#totuus_version").on('click', (e) => showDocumentation('eula'));
    }

    if ($("#eula_link").length) {
        $("#eula_link").on('click', (e) => showDocumentation('eula'));
    }

    if ($("#version_number").length) {
        $("#version_number").on('click', (e) => showDocumentation('release_notes'));
    }

    if ($("#release_notes_link").length) {
        $("#release_notes_link").on('click', (e) => showDocumentation('release_notes'));
    }
});

/**
 * *----------------------------------------------------------------------
 * * Function to show the popup of Documentation i.e. EULA / Release Notes
 * *----------------------------------------------------------------------
 * @param {String} type
 */
function showDocumentation(type) {
    let docName;
    let title;
    let requestUrl;
    // Check if the document is of type 'EULA'
    if (type == "eula") {
        // Set the title
        title = "End User License Agreement";
        // Set the docName to default EULA document name
        docName = "eulaFileName";
        // Show the document in sweetalert popup
        requestUrl = `/get-latest-eula`;
        // showDocumentInPopup(title, docName)
    } else if (type == "release_notes") {
        // Else if the document is of type 'Release Notes'
        // Set the title
        title = "Release Notes";
        // Set default release notes docuemnt filename
        docName = `TOTUUS - Release Notes (${$("#version_number").text().trim()}).pdf`;
        // Call an API to get the latest release notes document file name
        requestUrl = `/get-release-notes`;
    }

    $.ajax({
        type: "GET",
        url: HOST_URL + requestUrl,
        data: {
            paramData: docName,
        },

        success: function (response) {
            console.log(response);
            if (response.targetFileName) {
                response = response.targetFileName
            }
            // Show the file in sweeetalert popup
            showDocumentInPopup(title, response);
        },
        error: function (jqXHR, exception) {
            ajaxCallError(jqXHR, exception);
        },
    });
}

/**
 * *--------------------------------------------------------------------
 * * This funciton will show the specified document in Sweet alert popup
 * *--------------------------------------------------------------------
 * @param {String} title
 * @param {String} targetFileName
 */
function showDocumentInPopup(title, targetFileName) {
    // Show sweetalert for specified file with given title
    Swal.fire({
        title: title,
        text: `${window.location.origin}/${targetFileName}`,
        customClass : {
            popup: 'eula-popup-height',
        },
        html:
            `<embed src="${window.location.origin}/${targetFileName}" type="application/pdf" width="100%" height="100%" />`,
        confirmButtonText: 'Ok',
        width: '75%',
        heightAuto: false
    })

    // Set the Sweet alert's height to 700px
    $("#swal2-content").parent().css("height","700px");
    // Set the Sweet alert's content's height to the parent height
    $("#swal2-content").css("height","100%");
    // Remove the class of content block to enable clicks
    $("#swal2-content").removeClass('swal2-html-container');
}
